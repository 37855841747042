import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
    containerPortrait: {
        flex: 1,
        backgroundColor: '#f5f5f5',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    containerLandscape: {
        flex: 1,
        backgroundColor: '#f5f5f5',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    text: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#444',
        textAlign: 'center',
        marginBottom: 20,
    },
    videoContainer: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: "#fff",
        borderRadius: 10,
        overflow: 'hidden',
        marginBottom: 20,
        justifyContent: 'center', // Centers children along the vertical axis
        alignItems: 'center', // Centers children along the horizontal axis
    },
    video: {
        height: 200
    },
    buttonContainer: {
        marginTop: 15,
        width: 200,
        justifyContent: 'center',
        alignItems: 'center'
    },
    
    button: {
        backgroundColor: '#0782F9',
        padding: 10,
        borderRadius: 5,
        width: '60%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 15
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
    }
    
})

export default styles;
