import { KeyboardAvoidingView, TouchableOpacity, Text, View } from 'react-native'
import React from 'react'
import styles from './styles';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

const WelcomeScreen = ({navigation}) => {
  return (
    <KeyboardAvoidingView
      style={styles.container}
        behavior="padding" 
      >
      <Text style={styles.text}>
        Welcome! Start your journey below.
      </Text>
      <View>
        <TouchableOpacity 
          onPress={() => navigation.navigate('Lesson1Threading')}
          style={[styles.button, styles.buttonOutline]}
        >
          <Text style={styles.buttonOutlineText}>Lesson 1: Conversational Threading</Text>
        </TouchableOpacity>
      </View>
    </KeyboardAvoidingView>
  )
}

export default WelcomeScreen;

