import { KeyboardAvoidingView, Text, TouchableOpacity, View, Button } from 'react-native'
import React from 'react'
import styles from './styles';
import { Video, ResizeMode } from 'expo-av';
import * as ScreenOrientation from "expo-screen-orientation";
import { useEffect, useState } from "react";
import VideoPlayer from 'expo-video-player'

import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
const Lesson1Threading = ({navigation}) => {
    const video = React.useRef(null);
    const [status, setStatus] = React.useState({});
    const [orientation, setOrientation] = useState(ScreenOrientation.Orientation.UNKNOWN);

    useEffect(() => {
        fetchOrientation();
        const subscription = ScreenOrientation.addOrientationChangeListener(handleOrientationChange);
        return () => {
            ScreenOrientation.removeOrientationChangeListeners(subscription);
        }
    }, []);

    const fetchOrientation = async () => {
        const { orientation } = await ScreenOrientation.getOrientationAsync();
        setOrientation(orientation);
    };

    const handleOrientationChange = ({ orientationInfo: { orientation } }) => {
        setOrientation(orientation);
    };

    const handlePlayPause = async () => {
        if (status.isPlaying) {
            await video.current.pauseAsync();
        } else {
            await video.current.playAsync();
        }
    };

    return (
        <KeyboardAvoidingView
            style={orientation === ScreenOrientation.Orientation.PORTRAIT_UP ? styles.containerPortrait : styles.containerLandscape}
            behavior="padding"
        >
            <View style={styles.videoContainer}>
            <Video
                ref={video}
                style={[
                    styles.video,
                    orientation === ScreenOrientation.Orientation.PORTRAIT_UP
                        ? { width: '100%' }
                        : { width: '50%' },
                ]}
                source={{
                    uri: 'https://s3.amazonaws.com/dev.banterboost.com/media/threading-test.mp4',
                }}
                shouldPlay={false}
                resizeMode={Video.RESIZE_MODE_CONTAIN}
                isLooping={false}
                useNativeControls={true}
                onPlaybackStatusUpdate={setStatus}
            />
                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={styles.button} onPress={handlePlayPause}>
                        <Text style={styles.buttonText}>{status.isPlaying ? 'Pause Lesson' : 'Play Lesson'}</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Lesson 1: Practice')}>
                        <Text style={styles.buttonText}>Practice</Text>
                    </TouchableOpacity>
                </View>
            </View>
            
        </KeyboardAvoidingView>
    );
};

export default Lesson1Threading;
