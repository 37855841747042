// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
//import firebase from 'firebase/compat/app';
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDvCena-Vbz45eaLyet-qUcwCkiWwvcF-o",
  authDomain: "banterboost-acc32.firebaseapp.com",
  projectId: "banterboost-acc32",
  storageBucket: "banterboost-acc32.appspot.com",
  messagingSenderId: "846984502373",
  appId: "1:846984502373:web:964b4bd1a0dbf3c3637a8c",
  measurementId: "G-6QCLZ69J81"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };

//const auth = firebase.auth()

//export { auth };
// Initialize Firebase
// const analytics = getAnalytics(app);