import { KeyboardAvoidingView, Text, TouchableOpacity, View, TextInput } from 'react-native';
import React, { useState } from 'react';
import styles from './styles';
import * as ScreenOrientation from 'expo-screen-orientation';
import { useEffect } from 'react';
import { Audio } from 'expo-av';
import * as FileSystem from 'expo-file-system';

const Lesson1Drill = () => {
  const video = React.useRef(null);
  const [status, setStatus] = React.useState({});
  const [orientation, setOrientation] = useState(ScreenOrientation.Orientation.UNKNOWN);
  const [message, setMessage] = useState('');
  const [sound, setSound] = useState(null);

  useEffect(() => {
    fetchOrientation();
    const subscription = ScreenOrientation.addOrientationChangeListener(handleOrientationChange);
    return () => {
      ScreenOrientation.removeOrientationChangeListeners(subscription);
    };
  }, []);

  const fetchOrientation = async () => {
    const { orientation } = await ScreenOrientation.getOrientationAsync();
    setOrientation(orientation);
  };

  const handleOrientationChange = ({ orientationInfo: { orientation } }) => {
    setOrientation(orientation);
  };

  const handlePlayPause = async () => {
    if (status.isPlaying) {
      await video.current.pauseAsync();
    } else {
      await video.current.playAsync();
    }
  };

  const handleSendText = async () => {
    try {
      const response = await fetch('https://tx3xswrnrk.execute-api.us-east-1.amazonaws.com/dev/speech', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: message,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Speech URL:', data.url);
        // Download the audio file
        await downloadAudioFile(data.url);
      } else {
        console.error('Error:', response.status);
        // Handle the error response
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle the network error
    }
  };

  const downloadAudioFile = async (audioUrl) => {
    try {
      const downloadResumable = FileSystem.createDownloadResumable(
        audioUrl,
        FileSystem.documentDirectory + 'output.mp3'
      );

      const { uri } = await downloadResumable.downloadAsync();
      console.log('Downloaded audio file:', uri);

      // Play the downloaded audio file
      await playAudioFile(uri);
    } catch (error) {
      console.error('Error downloading audio file:', error);
      // Handle the download error
    }
  };

  const playAudioFile = async (audioFileUri) => {
    try {
      const { sound } = await Audio.Sound.createAsync({ uri: audioFileUri });
      setSound(sound);
      await sound.playAsync();
    } catch (error) {
      console.error('Error playing audio file:', error);
      // Handle the playback error
    }
  };

  const stopAudio = async () => {
    if (sound) {
      await sound.stopAsync();
    }
  };

  return (
    <KeyboardAvoidingView
      style={orientation === ScreenOrientation.Orientation.PORTRAIT_UP ? styles.containerPortrait : styles.containerLandscape}
      behavior="padding"
    >
      <View style={styles.videoContainer}>
        <Text>Let's practice</Text>
        <TextInput
          style={styles.input}
          onChangeText={setMessage}
          value={message}
          placeholder="Enter your message"
        />
        <TouchableOpacity style={styles.button} onPress={handleSendText}>
          <Text style={styles.buttonText}>Send</Text>
        </TouchableOpacity>
      </View>
      
    </KeyboardAvoidingView>
  );
};

export default Lesson1Drill;
